<template>
    <div id="nav">
      <div class="navItems" :class="{mobile: mobile}">
        <div v-for="item in menuItems" :key="item.id" class="linkHolder">
          <router-link v-if="!item.children" :to="item.link" @click="clearBurger()">{{item.name}}</router-link>
          <div v-if="!!item.children" @click="showChildren(item.id)">
            <span  class="parent">{{item.name}}</span>
            <!-- <div class="arrow" :class="{active: item.id == shownKidsForParent}">></div> -->
          </div>
          <NavChildren v-if="item.id == shownKidsForParent" :children="item.children" v-on:child-navigation="childNav" />
        </div>
      </div>
      <div class="burger">
        <Burger v-on:changed-active-state="burgered" ref="burger" />
      </div>
    </div>
</template>

<style>
#nav {
  align-items: flex-end;
  display: flex;
  margin-bottom: 10px;
}
.navItems {
  transition: opacity 400ms ease-in-out;
  display: flex;
}
#nav a, #nav .parent {
  text-decoration: none;
  color: var(--main-color-3);;
  padding: 5px 15px 5px 10px;
  font-size: 1.6em;
  transition: 0.5s;
  border-radius: 10px;
  font-family: 'Open Sans';
  user-select: none;
  cursor: pointer;
}
#nav a:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
#nav a.router-link-exact-active {
  color: navy;
  font-weight: bold;
}
.arrow {
  display: inline-block;
  margin-left: -10px;
  font-weight: bold;
  transform: rotate(-90deg);
  transition: transform 0.3s;
}
.arrow.active {
  transform: rotate(90deg);
}
.burger {
  display: none;
}
@media screen and (max-width: 900px) {
  #nav a, #nav .parent { font-size: 1.2rem; }
}
@media screen and (min-width:900px) and (max-width: 1000px) {
  #nav a, #nav .parent { font-size: 1.3rem; }
}

@media screen and (max-width: 750px) {
  #nav a, #nav .parent { font-size: 1.6rem; }
  .navItems{
    display: flex;
    overflow: hidden;
    width: 0;
    opacity: 0;
  }
  .navItems.mobile {
    visibility:visible;
    display: flex;
    flex-direction: column;
    /* background-color: rgba(0, 0, 0, 0.9); */
    background-color: rgba(230,230,230,0.97);
    width: 300px;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: unset;
    height: 100%;
    opacity: 1;
  }
  .burger {
    display: unset;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .arrow {
    color: white;
  }
  .linkHolder {
    margin-bottom: 20px;
  }
}
</style>

<script>
import axios from 'axios'
import Burger from './Burger.vue'
import NavChildren from './NavChildren.vue'

export default {
  name: 'Nav',
  components: {
    Burger,
    NavChildren
  },
  data() {
    return {
      menuItems: [],
      mobile: false,
      shownKidsForParent: -1
    }
  },
  mounted() {
    axios
      .get(`/data/menu.json`)
      .then(response => {
          this.menuItems = response.data;
        })
  },
  methods: {
    burgered(e) {
      this.mobile = e[0];
    },
    clearBurger() {
      this.$refs.burger.clear();
      this.mobile = false;
      this.shownKidsForParent = -1;
    },
    showChildren(id){
      if (this.shownKidsForParent == id) {  
        this.shownKidsForParent = -1;
        return;
      }
      this.shownKidsForParent = id;
    },
    childNav() {
      this.shownKidsForParent = -1;
      this.$refs.burger.clear();
      this.mobile = false;
    }
  }
}
</script>