<template>
  <h2> - <slot></slot> - </h2>
</template>

<style scoped>
  h2{
    text-transform:capitalize;
    font-weight: bold;

    background-image: linear-gradient(to bottom, var(--main-color-2), var(--main-color-3));
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 5px;

    letter-spacing: 1px;
  }
</style>