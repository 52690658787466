<template>
  <div class="home">
    <PageContent>
    <div class="main">
      <div class="left"><LoadHtml fileName="home_left" /></div>
      <div class="right">
        <div class="rightLimiter"> 
          <LoadHtml fileName="home_right" />

        </div>
      </div>
    </div>
    </PageContent>
    <div class="cards">
      <Cards :limit="cardLimit" />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import LoadHtml from '@/components/LoadHtml.vue'
import PageContent from '@/components/PageContent.vue'
import Cards from '@/components/Cards.vue'

export default {
  name: 'Home',
  components: {
    LoadHtml,
    Cards,
    PageContent
  },
  data(){
    return {
      cardLimit: 3
    }
  }
}
</script>


<style scoped>
.main {
  width: 100%;
  display: flex;
  color: white;
}
.main > .left {
  flex: 8;
  text-align: end;
  padding: 10px;
  color: var(--main-color-3);
  margin-left: 20px;
}
h2.fp_l_subHeading {
  color: var(--main-color-3);
}
.main > .right {
  flex: 12;
  padding-left: 10px;
  padding: 10px;
  background: var(--main-color-3);
  border-radius: 20px;
  margin-right: 10px;
}
.cards {
  margin: 0 auto;
  text-align: center;
  max-width: 1100px;
}
.rightLimiter {
  max-width: 700px;
}
@media screen and (max-width: 750px){
  .main {
    flex-direction: column;
  }
  .left {
    order: 1;
  }
}
</style>