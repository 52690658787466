<template>
  <div class="appfiller">
    <AdminLink />
    <div class="appHeader">
      <div class="f1"></div>
      <div class="logo">
        <img src="/images/IMSN.png" alt="" class="logo" @click="goHome">
      </div>
      <div class="navDiv">
        <Nav/>
      </div>
      <div class="f1"></div>
    </div>
    <main>
      <router-view/>
    </main>
    <footer>
      <LoadHtml fileName="footer"/>
    </footer>
  </div>
</template>

<style>

body, html, .appfiller {
  height: 100%;
  margin: 0;
}
.appfiller {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: navy;
  height: 100%;
}
.logo {
  width: 150px;
  cursor: pointer;
}
main {
  flex: 1 0 auto;
}
.navDiv
{
  display: flex;
  width: 1000px;
  padding-bottom: 20px;
}
.appHeader {
  display: flex;
}
.f-cont { display: flex; }
.f1 { flex: 1; }
.f2 { flex: 2; }
:root {
	--main-color-0: #fff;
	--main-color-1: #0bbf5f;
	--main-color-2: #0a93c9;
  --main-color-3: #1c6ba0;
  /* --main-color-3: #0a5bbf; */
	--main-color-4: #0bbf5f;
	--main-color-5: #01afb2;
	--main-color-6: #000000;
	--main-color-g: #01AFAF;
}
footer {
  /* height: 40px; */
  /* overflow: hidden; */
}
h2 {
  font-family: sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  margin-top: 0;
}

@media screen and (max-width: 750px) {
  .navDiv{
    display: contents;
  }
  .f-cont { flex-direction: column; }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import {mapActions} from 'vuex';

import LoadHtml from '@/components/LoadHtml.vue'
import Nav from '@/components/Nav/Nav.vue'
import AdminLink from '@/components/Admin/AdminLink.vue'
import AuthService from '@/services/AuthService'

export default {
  name: 'App',
  components: {
    LoadHtml,
    Nav,
    AdminLink
  },
  methods: {
    goHome () {
      this.$router.push('/');
    },
    ...mapActions(['setAuthState'])
  },
  async mounted() {
    const auth = AuthService.getTokenFromSession();
    if (auth) {
      await this.setAuthState(auth);
    }
  }
}
</script>