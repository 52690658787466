<template>
 <Suspense>
   <template #default>
     <div v-html="html"></div>
   </template>
   <template #fallback>
     Article not found!!!
   </template>
 </Suspense>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LoadHtml',
  props: {
    fileName: String
  },
  data() {
    return {
      html: ""      
    }
  },
  mounted() {
    this.loadHtml();
  },
  watch: {
    fileName() {
      this.loadHtml();
    }
  },
  methods: {
    loadHtml() {
      axios
        .get(`/articles/${this.fileName}.html`)
        .then(response => (this.html = response.data))
        .catch(() => this.html = `<h2 style="color: red;">Article [${this.fileName}] not found</h2>`)
        ;
    }
  }
}
</script>

