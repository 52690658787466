<template>
  <div class="container" :class="{ change: active }" v-on:click="click">
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
  </div>
</template>

<style scoped>
  .container {
    display: inline-block;
    cursor: pointer;
  }

  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: var(--main-color-3);
    margin: 6px 0;
    transition: 0.2s;
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }

  .change .bar2 {opacity: 0;}

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
</style>

<script>
export default {
  name: 'Burger',
  data() {
    return {
      active: false
    }
  },
  methods: {
    click() {
      this.active = !this.active;
      this.$emit('changed-active-state', [this.active])
    },
    clear() {
      if (this.active){
        this.active = false;
      }
    }
  }
}
</script>