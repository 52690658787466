import AuthService from '../services/AuthService.js'
import { createStore } from 'vuex'

export default createStore({
  strict: true,
  state: {
      auth: {
        userName: "",
        validUntil: new Date(1900,0,1),
        token: ""
      }
  },
  mutations: {
    setAuth (_, auth) {
      this.state.auth = {
        userName: auth.nameid,
        validUntil: auth.exp,
        token: auth.tkn
      };
    },
    logout () {
      this.state.auth = {
        userName: "",
        validUntil: new Date(1900,0,1),
        token: ""
      }
    }
  },
  actions: {
    async logIn ({dispatch}, payload) {
      const res = await AuthService.login(payload.userName, payload.password);
      if (res) {
        dispatch('setAuthState', res);
      }
    },
    setAuthState({commit}, token) {
      const auth = AuthService.parseJwt(token);
      commit('setAuth', {tkn: token, nameid: auth.nameid, exp: auth.exp});
    }
  },
  getters: {
    getAuth: (state) => () => state.auth,
    token: (state) => state.auth.token
  }
})
