import axios from "axios";
import Config from '@/config/index.js'

const login = async (userName, password) => {
  var res = await axios.post(`${Config.baseAPIPath}/Auth`, 
    {userName, password}, 
    {headers : { 'Access-Control-Allow-Origin': '*' }});

  sessionStorage.setItem('tkn', res.data);

  return res.data;
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const getAuthFromSession = () => {
  const tkn = getTokenFromSession();
  if (!tkn) {
    return null;
  }
  return parseJwt(tkn);
}
const getTokenFromSession = () => {
  return sessionStorage.getItem('tkn');
}

const logout = () => {
  sessionStorage.removeItem('tkn');
}

export default { login, getAuthFromSession, parseJwt, logout, getTokenFromSession }