<template>
  <div class="cHolder">
    <router-link v-for="(item,i) in children" :to="item.link" :key="i" @click="childClicked()">{{item.name}}</router-link>
  </div>
</template>

<style scoped>
  .cHolder {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    padding: 5px;
  }
  @media screen and (max-width: 750px){
    .cHolder {
      position: relative;
      background-color: unset;
    }
  }
</style>

<script>
export default {
  name: 'NavChildren',
  props: {
    children: Array
  },
  methods: {
    childClicked() {
      this.$emit('child-navigation', [this.active])
    }
  }
}
</script>