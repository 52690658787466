<template>
  <div v-if="getAuth().userName">
    <router-link to="/admin">Admin [{{getAuth().userName}}]</router-link>
  </div>
</template>
<style scoped>
  div {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  @media screen and (max-width: 750px) {
    div {
    top: 40px;
    }
  }
</style>
<script>
import { mapGetters } from 'vuex'
export default {

  computed: {
    ...mapGetters(['getAuth'])
  }
}
</script>