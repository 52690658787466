<template>
<PageContent>
  <SubHeading>News</SubHeading>
  <LoadHtml fileName="news" />
  <Cards :limit="20" />
</PageContent>
</template>

<script>
import Cards from '@/components/Cards.vue';
import LoadHtml from '@/components/LoadHtml.vue';
import PageContent from '@/components/PageContent.vue';
import SubHeading from '@/components/SubHeading.vue';

export default {
  name: 'News',
  components: {
    LoadHtml,
    Cards,
    PageContent,
    SubHeading
  }
}
</script>