<template>
  <PageContent>
    <SubHeading>Contact Us</SubHeading>

    <div class="email">
      <div class="left">Email:</div>
      <div class="right">info@maternitysupport.ie</div>
    </div>

    <div class="contactUs">
      <div class="left">
        <img src="/images/envelope.png" alt="Contact Us Image" />
        <p>We aim to reply within 24 hours</p>
      </div>
      <div class="right">
        <div>
          <div class="fs">
            <label for="Name">Name:</label>
            <input type="text" id="Name" name="Name" v-model="name" />
          </div>
          <div class="fs">
            <label for="Email">Email:</label>
            <input type="text" id="Email" name="Email" v-model="email" />
          </div>
          <div class="fs">
            <label for="Phone">Phone:</label>
            <input placeholder="Optional" type="text" id="Phone" name="Phone" v-model="phone" />
          </div>
          <div class="fs">
            <label for="Subject">Subject:</label>
            <input type="text" id="Subject" name="Subject" v-model="subject" />
          </div>
          <div class="fs">
            <label for="Body" class="lblDetails">Details:</label>
            <textarea rows="5" id="Body" name="Body" v-model="details"></textarea>
          </div>
          <div class="submit">
            <button @click="Send" v-if="!sent">Submit</button>
            <span v-if="sent">Message as been sent</span>
          </div>
        </div>
      </div>
    </div>
  </PageContent>
</template>

<style scoped>
.email {
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 8px;
  width: 570px;
  border-radius: 10px;
}
.email .left {
  background-color: var(--main-color-3);
  font-size: 1.5rem;
  padding: 5px 10px;
  border-radius: 10px 0 0 10px;
  color: var(--main-color-0);
}
.email .right {
  background-color: var(--main-color-0);
  font-size: 1.5rem;
  padding: 5px 20px 5px 20px;
}

.contactUs {
  background-color: rgba(0, 100, 50, 0.1);
  display: flex;
  padding: 8px;
  width: 570px;
  border-radius: 10px;
  margin-top: 20px;
}
.contactUs .left {
  background-color: var(--main-color-3);
  font-size: 1.2rem;
  padding: 5px 10px;
  border-radius: 10px 0 0 10px;
  color: var(--main-color-0);
  max-width: 150px;
  text-align: center;
}
.contactUs .right {
  background-color: var(--main-color-0);
  font-size: 1.1rem;
  padding: 5px 20px 5px 20px;
}
label {
  min-width: 100px;
  display: inline-block;
}
input, textarea {
  min-width: 250px;;
  margin-top: 15px;
}
.fs {
  vertical-align: top;
}
.submit {
  display: flex;
  flex-direction: row-reverse;
}
.submit button {
  font-size: 1.5rem;
  background-color: var(--main-color-3);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
}
.submit button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.lblDetails{
  vertical-align: top;
  padding-top: 12px;
}
@media screen and (max-width: 750px) {
  .email {
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
  }
  .email .left {
    border-radius: 10px 10px 0 0;
  }
  .email .right {
    font-size: 0.9rem;
    text-align: center;
    padding: 5px 0;
  }
  .contactUs {
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
  }
  .contactUs .left {
    border-radius: 10px 10px 0 0;
    max-width: unset;
  }
  .fs {
    display: flex;
    align-items: center;
  }
  input, textarea {
    min-width: unset;
    flex: 1;
    margin: 8px;
  }
  p {
    margin: 5px;
  }
  br {
    height: 1px;
  }
}

</style>


<script>
import PageContent from "@/components/PageContent.vue";
import SubHeading from "@/components/SubHeading.vue";

import MailService from '@/services/MailService'

export default {
  name: "Contact",
  components: {
    PageContent,
    SubHeading
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      subject: "",
      details: "",
      sent: false,
    }
  },
  methods: {
    async Send(){
      this.sent = true;
      await MailService.SendMail(this.email, this.name, this.phone, this.subject, this.details)
    }
  }
};
</script>