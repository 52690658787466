import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '@/views/Home.vue'
import Contact from '@/views/Contact.vue'
import News from '@/views/News.vue'
import ViewArticle from '@/components/ViewArticle.vue'

const Admin = () => import('@/views/Admin/Admin.vue');
const Login = () => import('@/views/Admin/Login.vue');
const Images = () => import('@/views/Admin/Images.vue');
const EditCards = () => import('@/views/Admin/EditCards.vue');
const Articles = () => import('@/views/Admin/Articles.vue');
const Article = () => import('@/views/Admin/Article.vue');
const Files = () => import('@/views/Admin/Files.vue');
const Menu = () => import('@/views/Admin/Menu.vue');

import store from '@/store';

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/about', component: ViewArticle, props: { name: 'about' } },
  { path: '/support', component: ViewArticle, props: { name: 'support' }, meta: {title: 'Support'} },
  { path: '/information', component: ViewArticle, props: { name: 'information' }, meta: {title: 'Information'} },

  { path: '/doc/:name', component: ViewArticle, props: true, meta: {title: 'Support'}},

  { path: '/news', component: News, meta: {title: 'News'} },
  { path: '/contact', name: 'Contact', component: Contact, meta: {title: 'Contact'} },
  { path: '/login', name: 'Login', component: Login, meta: {title: 'Login'} },

  { path: '/admin', name: 'Admin', component: Admin, meta: {title: 'Admin'} },
  { path: '/admin/articles/:name', name: 'Article', component: Article, meta: {title: 'Article'} },
  { path: '/admin/articles', name: 'Articles', component: Articles, meta: {title: 'Articles'} },
  { path: '/admin/cards', name: 'EditCards', component: EditCards, meta: {title: 'Cards'} },
  { path: '/admin/images', name: 'Images', component: Images, meta: {title: 'Images'} },
  { path: '/admin/files', name: 'Files', component: Files, meta: {title: 'Files'} },
  { path: '/admin/menu', name: 'Menu', component: Menu, meta: {title: 'Menu'} },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path.toLocaleLowerCase().startsWith('/admin') && !store.getters.token) {
    next({ name: 'Home' });
    return;
  }

  if (to.params.name){
    document.title = `IMSN-${to.params.name}`;
  } else {
    document.title = to.meta.title ? `IMSN-${to.meta.title}` : 'IMSN';
  }
  next();
})

export default router
