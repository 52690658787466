<template>
    <PageContent>
      <SubHeading>{{!!title ? title : name}}</SubHeading>
      <LoadHtml v-bind:fileName="name"/>
    </PageContent>
</template>

<script>
import LoadHtml from '@/components/LoadHtml.vue'
import PageContent from '@/components/PageContent.vue';
import SubHeading from '@/components/SubHeading.vue';

export default {
  name: 'ViewArticle',
  components: {
    LoadHtml,
    PageContent,
    SubHeading
  },
  props: {
    name: String,
    title: String
  },
}
</script>

<style scoped>
  h2{
    text-transform:capitalize;
    font-weight: bold;

    background-color: var(--main-color-2);
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
  }
</style>