import axios from "axios";
import Config from '@/config/index.js'
//import store from '@/store'

const SendMail = async (from, name, phone, subject, details) => {
  //const token = store.getters.token;

  var result = await axios
  .post(
    `${Config.baseAPIPath}/SendMail`,
    {"from": from, "name": name, "phone": phone, "subject": subject, "details": details},
    { 
      headers: { "Access-Control-Allow-Origin": "*" },
    }
  );
  return result.data;
}

export default {
  SendMail
}