<template>
  <div class="cards">
    <Card v-for="(card, i) in Cards" :key="i"
      :index="i"
      :title="card.title" 
      :summary="card.summary"
      :imageSrc="card.imageSrc"
      :date="card.date"
      :link="card.link"
      :linkTxt="card.linkTxt"
      :editMode="editMode"
      @card-clicked="CardClicked"
    />
  </div>  
</template>

<script>
import axios from 'axios';

import { ref, onMounted, watch } from 'vue';
import Card from '@/components/Card.vue'

export default {
  name: 'Cards',
  components: {
    Card
  },
  props: {
    limit: Number,
    editMode: Boolean,
    externalSource: Array
  },

  setup(props, ctx){
    let Cards = ref([]);
    onMounted(function() {
      if(props.externalSource) {
        Cards.value = props.externalSource; 
        return; 
      }
      axios.get('/data/cards.json')
        .then(res => Cards.value = res.data.sort((a,b) => (a.date > b.date) ? -1 : 1).slice(0,props.limit));
    });

    watch(() => props.externalSource, (oldVal, newVal) => {
      Cards.value = newVal;
    });

    function CardClicked (event) {
      ctx.emit("card-clicked", event)
    }

    return { Cards, CardClicked }
  }
}

</script>

<style scoped>
  .cards {
    display: flex;
    width: 100%; 
    align-content: space-between;
    flex-basis: 200px;
    flex-wrap: wrap;
    justify-content: space-around;
  }
</style>