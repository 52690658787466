<template>
  <article @click="$emit('card-clicked', index)" :class="{hand: editMode}">
    <img :src="imageSrc" alt="">
    <a v-if="!editMode" class="title" :href="link" >{{title}}</a>
    <a v-if="editMode" class="title" >{{title}}</a>
    <div class="date">{{parseDate(date)}}</div>
    <div class="summary">{{summary}}</div>
    <a v-if="!editMode" class="link" :href="link" >{{linkTxt}}</a>
    <a v-if="editMode" class="link" >{{linkTxt}}</a>
  </article>
</template>

<script>
  export default {
    name: 'Card',
    props: {
      imageSrc: String,
      title: String,
      date: String,
      summary: String,
      link: String,
      linkTxt: String,
      index: Number,
      editMode: Boolean
    },
    methods: {
      parseDate(date) {
        return new Date(date).toISOString().slice(0,10);
      }
    }
  }
</script>

<style scoped>
  article {
    display: flex;
    flex-direction: column;
    width: 350px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px;
    overflow: hidden;
    margin: 5px;
  }
  article > div, article > a {
    margin: 5px 10px;
  }
  .title{ 
    font-weight: bold; 
    text-decoration: none;
    color: var(--main-color-3);
  }

  img {min-height: 100px; }
  .link {
    color: var(--main-color-3);
    text-decoration: none;
    align-self: flex-end;
  }
  .title:hover, .link:hover {
    text-decoration: underline;
  }
  .date {
    font-size: 0.8rem;
    color:rgba(0, 0, 0, 0.3);
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .hand {
    cursor: pointer;
  }

</style>